import { ValueOf } from "../../../pojo/Util";
import { DocumentListItem } from "../../../services/document.service";
import { Report as Reports } from "../../../pojo/Report";
import { S25Util } from "../../../util/s25-util";

export namespace Doc {
    export type Scope = ValueOf<typeof scope>;
    export type ScopeId = Scope["id"];
    export type ScopeReportId = Scope["reportId"];

    export const scope = {
        event: {
            id: "event",
            label: "Event Based",
            order: 0,
            reportId: Reports.Reports.EventShell.id,
            type: "standard",
        },
        reservation: {
            id: "reservation",
            label: "Reservation Based",
            order: 1,
            reportId: Reports.Reports.ReservationShell.id,
            type: "standard",
        },
        organization: {
            id: "organization",
            label: "Organization Based",
            order: 2,
            reportId: Reports.Reports.OrganizationShell.id,
            type: "standard",
        },
        invoice: {
            id: "invoice",
            label: "Invoice (Payment)",
            order: 2.1,
            reportId: Reports.Reports.PaymentShell.id,
            type: "standard",
        },
        eventListing: {
            id: "eventListing",
            label: "Event Listing - Excel",
            order: 3,
            reportId: Reports.Reports.EventListingShell.id,
            type: "tableBuilder",
            tableBuilder: [{ id: "data", label: "Data", key: "eventListing", variable: "ws.flexTable.events" }],
        },
        rsrvListing: {
            id: "rsrvListing",
            label: "Reservations By Date - Excel",
            order: 4,
            reportId: Reports.Reports.ReservationListingShell.id,
            type: "tableBuilder",
            tableBuilder: [
                { id: "data", label: "Data", key: "reservationListing", variable: "ws.flexTable.rsrvsByDate" },
            ],
        },
        locListing: {
            id: "locListing",
            label: "Location Listing - Excel",
            order: 5,
            reportId: Reports.Reports.LocationListingShell.id,
            type: "tableBuilder",
            tableBuilder: [{ id: "data", label: "Data", key: "locationListing", variable: "ws.flexTable.locations" }],
        },
        resListing: {
            id: "resListing",
            label: "Resource Listing - Excel",
            order: 6,
            reportId: Reports.Reports.ResourceListingShell.id,
            type: "tableBuilder",
            tableBuilder: [{ id: "data", label: "Data", key: "resourceListing", variable: "ws.flexTable.resources" }],
        },
        orgListing: {
            id: "orgListing",
            label: "Organization Listing - Excel",
            order: 7,
            reportId: Reports.Reports.OrganizationListingShell.id,
            type: "tableBuilder",
            tableBuilder: [
                { id: "data", label: "Data", key: "organizationListing", variable: "ws.flexTable.organizations" },
            ],
        },
        invoiceExcel: {
            id: "invoiceExcel",
            label: "Invoice Details - Excel (Payment)",
            order: 8,
            reportId: Reports.Reports.InvoiceShell.id,
            type: "tableBuilder",
            tableBuilder: [
                {
                    id: "summary",
                    label: "Summary",
                    key: "invoicePaymentSummary",
                    variable: "ws.flexTable.invoicePaymentSummary",
                },
                {
                    id: "details",
                    label: "Details",
                    key: "invoicePaymentDetails",
                    variable: "ws.flexTable.invoicePaymentDetails",
                },
            ],
        },
    } as const;
    export const scopeOptions = Object.values(scope).sort((a, b) => a.order - b.order);

    export type ScopeOptions = typeof scopeOptions;

    export const scopeById = new Map(Object.values(scope).map((scope) => [scope.id, scope]));

    // Parses the columns of a table builder table variable
    // Format: {{for: <table variable> : <column1> || <column2> || ... :end-for}}
    // Columns: <variable> | <format> : <header> : <width>
    // Where only variable and header are required
    export const tableBuilderColumnRegex =
        /(?<variable>[^:|]+)\s*(?:\|\s*(?<format>[^:|]+)\s*)?:\s*(?<header>[^:|]+)\s*(?::\s*(?<width>\d+)\s*)?\s*(?:\|\||:end-for)/gi;

    export const tableBuilderRegex = /(?<table>\{\{for:\s*(?<variable>[$.\w\d]+):\s*(?<columns>.*?):end-for}})/gi;

    export function documentToReport(document: DocumentListItem): Reports.SimpleObject {
        const documentScope = Doc.scopeById.get(document.type);
        const shellReport = Reports.reportsById.get(documentScope.reportId);
        return {
            rpt_id: document.reportId || documentScope.reportId,
            rpt_engine: "DM",
            rpt_name: `${S25Util.capitalizeWords(documentScope.id)} Document: ${document.itemName}`,
            document_id: document.itemId,
            object_type: shellReport.object_type,
            rpt_use: Reports.Use.None,
        };
    }
}
