import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { Document, DocumentService } from "../../../services/document.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { EventService } from "../../../services/event.service";
import { ReportService } from "../../../services/report.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { TableBuilder } from "../../bpe/s25.table.builder.component";
import { S25ModalComponent } from "../../s25-modal/s25.modal.component";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { Doc } from "./s25.document.const";
import { Report, Report as Reports } from "../../../pojo/Report";
import Use = Report.Use;
import ObjectType = Report.ObjectType;
import { BpeVarsConst } from "../../bpe/s25.bpe.const";
import { TabGroupTab } from "../../../standalone/s25.tab.group.component";
import { VariableI } from "../../../pojo/VariableI";
import { BpeVarsUtil } from "../../bpe/s25.bpe.vars.util";
import { TelemetryService } from "../../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-edit-document")
@Component({
    selector: "s25-ng-edit-document",
    template: `
        @if (isInit) {
            <label for="document-name" class="ngBold">Document Name</label>
            @if (readOnly) {
                <span>{{ document.document_name }}</span>
            } @else {
                <input
                    [(ngModel)]="document.document_name"
                    id="document-name"
                    name="document-name"
                    class="c-input"
                    type="text"
                    maxlength="64"
                />
            }
            <label for="document-scope" class="ngBold">Document Scope </label>
            @if (mode === "edit" || readOnly) {
                <div>
                    <p class="static-scope">{{ scope.label }}</p>
                </div>
            } @else {
                <select
                    [ngModel]="scope"
                    (ngModelChange)="onScopeChange($event)"
                    id="document-scope"
                    name="document-scope"
                    class="c-input"
                >
                    @for (option of scopeOptions; track option) {
                        <option [ngValue]="option">{{ option.label }}</option>
                    }
                </select>
            }
            <label for="output-filename" class="ngBold">Output Filename </label>
            @if (readOnly) {
                <span>{{ document.file_name }}</span>
            } @else {
                <input
                    id="output-filename"
                    name="output-filename"
                    class="c-input"
                    [placeholder]=""
                    [(ngModel)]="document.file_name"
                />
            }
            <label for="output-filename" class="ngBold description">Description </label>
            @if (readOnly) {
                <div [innerHTML]="document.description | safeHTML"></div>
            } @else {
                <s25-ng-rich-text-editor [(modelValue)]="document.description" />
            }
            @if (scope.type === "standard") {
                @if (readOnly) {
                    <label class="ngBold">Header</label>
                    <div class="rendered" [innerHTML]="document.header_text | safeHTML"></div>

                    <label class="ngBold">Body</label>
                    <div class="rendered" [innerHTML]="document.body_text | safeHTML"></div>

                    <label class="ngBold">Footer</label>
                    <div class="rendered" [innerHTML]="document.footer_text | safeHTML"></div>
                }
                @if (!readOnly) {
                    <label class="ngBold">Header</label>
                    <s25-ng-rich-text-editor
                        [(modelValue)]="document.header_text"
                        [defaultHeight]="300"
                    ></s25-ng-rich-text-editor>

                    <label class="ngBold">Body</label>
                    <s25-ng-rich-text-editor
                        [(modelValue)]="document.body_text"
                        [defaultHeight]="550"
                    ></s25-ng-rich-text-editor>

                    <label class="ngBold">Footer</label>
                    <s25-ng-rich-text-editor
                        [(modelValue)]="document.footer_text"
                        [defaultHeight]="300"
                    ></s25-ng-rich-text-editor>

                    <div
                        class="simple-collapse--wrapper c-margin-top--single templateVarsCollapse c-objectDetails--borderedSection"
                    >
                        <div class="c-sectionHead">
                            <h2>Template Variables</h2>
                        </div>
                        <s25-simple-collapse [defaultCollapsed]="true" [titleText]="'Template Variables'">
                            <div class="c-evddWrapper">
                                <bpe-vars [scope]="document.document_type" [type]="'document'"></bpe-vars>
                            </div>
                        </s25-simple-collapse>
                    </div>
                }
                <label class="ngBold" for="preview-reference">Preview by Reference</label>
                <input
                    [(ngModel)]="eventReference"
                    id="preview-reference"
                    name="preview-reference"
                    class="c-input"
                    placeholder="YYYY-ABCDEF"
                    (keydown.enter)="showPreview()"
                />
                <button class="aw-button aw-button--primary" (click)="showPreview()">
                    @if (document.document_type === "reservation") {
                        Load Reservations
                    } @else if (document.document_type === "invoice") {
                        Load Invoices
                    } @else {
                        Preview
                    }
                </button>
                @if (document.document_type === "reservation" && eventReference && eventId) {
                    <div>
                        <label class="ngBold c-margin-top--single">Select occurrence</label>
                        <s25-ng-document-reservation-list
                            [eventId]="eventId"
                            (selected)="showPreview($event)"
                        ></s25-ng-document-reservation-list>
                    </div>
                }
                @if (document.document_type === "invoice" && eventReference && eventId) {
                    <div>
                        <label class="ngBold c-margin-top--single">Select Invoice</label>
                        <s25-ng-document-invoice-list
                            [eventId]="eventId"
                            (selected)="showPreview($event)"
                        ></s25-ng-document-invoice-list>
                    </div>
                }
            }
            @if (scope.type === "tableBuilder") {
                @if (view === "form") {
                    @if (scope.tableBuilder.length > 1 && view === "form") {
                        <s25-ng-tab-group [tabs]="scope.tableBuilder" [(chosen)]="tableTab" />
                    }
                    @for (table of scope.tableBuilder; track $index) {
                        @if (table.id === tableTab.id) {
                            @if (scope.tableBuilder.length === 1) {
                                <h3 class="ngBold c-margin-top--single">{{ table.label }}</h3>
                            }
                            <s25-ng-table-builder
                                [(columns)]="tables[$index]"
                                [variableType]="table.key"
                                [dateFormatType]="'tableDocumentDateFormats'"
                                [hasDataColumn]="true"
                                [hasWidthColumn]="true"
                                [maxWidth]="10"
                                (columnsChange)="onColumnsChange()"
                            ></s25-ng-table-builder>
                        }
                    }
                } @else if (view === "code") {
                    <h3 class="ngBold c-margin-top--single">Data</h3>
                    <textarea [(ngModel)]="document.body_text" class="codeMode"></textarea>
                }
                <h3 class="ngBold c-margin-top--single">Advanced</h3>
                <button class="aw-button aw-button--outline d-block" (click)="toggleView()">
                    {{ view === "form" ? "Code" : "Form" }} View
                </button>
                <h3 class="ngBold c-margin-top--single">Preview</h3>
                <div class="queryPreview">
                    @if (scope.id === "eventListing" || scope.id === "rsrvListing" || scope.id === "invoiceExcel") {
                        <label>Start</label>
                        <s25-datepicker
                            [modelValue]="queryPreview.start"
                            (modelValueChange)="queryPreview.start.date = $event; onPreviewDateChange()"
                        ></s25-datepicker>
                        <label>End</label>
                        <s25-datepicker
                            [modelValue]="queryPreview.end"
                            (modelValueChange)="queryPreview.end.date = $event; onPreviewDateChange()"
                            [minDate]="queryPreview.start.date"
                        ></s25-datepicker>
                        <label>
                            Event Search
                            @if (scope.id === "invoiceExcel") {
                                <span class="small">(optional)</span>
                            }
                        </label>
                        <s25-ng-search-dropdown
                            [type]="'event'"
                            [allowNonQueryId]="false"
                            [(chosen)]="queryPreview.eventSearch"
                        ></s25-ng-search-dropdown>
                    }
                    @if (scope.id === "rsrvListing" || scope.id === "locListing") {
                        <label>
                            Location Search
                            @if (scope.id === "rsrvListing") {
                                <span class="small">(optional)</span>
                            }
                        </label>
                        <s25-ng-search-dropdown
                            [type]="'location'"
                            [allowNonQueryId]="false"
                            [(chosen)]="queryPreview.locationSearch"
                        ></s25-ng-search-dropdown>
                    }
                    @if (scope.id === "resListing") {
                        <label>Resource Search</label>
                        <s25-ng-search-dropdown
                            [type]="'resource'"
                            [allowNonQueryId]="false"
                            [(chosen)]="queryPreview.resourceSearch"
                        ></s25-ng-search-dropdown>
                    }
                    @if (scope.id === "orgListing") {
                        <label>Organization Search</label>
                        <s25-ng-search-dropdown
                            [type]="'organization'"
                            [allowNonQueryId]="false"
                            [(chosen)]="queryPreview.organizationSearch"
                        ></s25-ng-search-dropdown>
                    }
                </div>
                <button class="aw-button aw-button--outline" (click)="showQueryPreview()">Preview</button>
                <s25-ng-modal #confirmExitCodeMode [type]="'confirm'" [title]="'Switch to Form View'">
                    Changes made to code may be lost when switching to Form View. Are you sure that you want to switch?
                </s25-ng-modal>
            }
            @if (isTemplate && mode !== "copy" && !!document.sampleFilename) {
                <label class="ngBold" for="preview-reference">Sample</label>
                <s25-ng-button [type]="'outline'" (click)="onSampleClick()">View Sample</s25-ng-button>
            }

            <s25-loading-inline [model]="{}"></s25-loading-inline>
            <div class="buttons c-margin-top--single">
                @if (mode !== "view") {
                    <button class="aw-button aw-button--primary" (click)="onSave()">Save</button>
                } @else {
                    <button class="aw-button aw-button--primary" (click)="onCopy()">Copy</button>
                }
                <button class="aw-button aw-button--outline" (click)="onCancel()">
                    {{ mode !== "view" ? "Cancel" : "Close" }}
                </button>
            </div>
        }
    `,
    styles: `
        label {
            display: block;
        }

        label:not(:first-child) {
            margin-top: 1em;
        }

        .buttons {
            display: flex;
            gap: 0.5em;
        }

        .codeMode {
            width: 100% !important;
            min-height: 5.5em;
            padding: 0.5em;
        }

        .queryPreview {
            display: grid;
            gap: 0.5em;
            grid-template-columns: auto 1fr;
            max-width: 500px;
            align-items: center;
            padding-bottom: 0.5em;
        }

        .queryPreview label {
            margin: 0 !important;
        }

        ::ng-deep s25-ng-edit-document s25-ng-rich-text-editor .tox-tinymce {
            width: 100% !important;
        }

        #preview-reference {
            margin-right: 0.5em;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }

        ::ng-deep .s25-multiselect-popup .s25-multiselect-columns-container {
            max-height: 35vh;
        }

        #output-filename {
            width: min(100%, 20em);
        }

        .static-scope {
            padding-top: 0.25rem;
        }

        .rendered {
            background-color: #f6f6f6;
            padding: 0.5rem;
            border: 1px dashed;
            margin-top: 0.25rem;
        }

        s25-ng-tab-group {
            padding-top: 1rem;
        }

        .description + s25-ng-rich-text-editor {
            margin-bottom: 4rem;
            display: block;
        }

        ::ng-deep .nm-party--on s25-ng-edit-document .rendered {
            background-color: #4c4d55;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditDocumentComponent implements OnInit {
    @Input() id: number;
    @Input() mode: "create" | "copy" | "view" | "edit" = "edit";
    @Input() isTemplate: boolean = false;

    @Output() saved = new EventEmitter<void>();
    @Output() copied = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();

    @ViewChild("confirmExitCodeMode") confirmExitCodeMode: S25ModalComponent;

    isInit = false;
    scope: Doc.Scope;
    document: Document;
    eventReference: string;
    eventId: number;
    originalDocument: Document;
    defaultFileNames: Record<Doc.ScopeReportId, string>;
    tables: TableBuilder.Column[][] = [];
    tableTab: TabGroupTab;
    queryPreview = {
        start: { date: new Date() },
        end: { date: new Date() },
        eventSearch: null as DropDownItem,
        locationSearch: null as DropDownItem,
        resourceSearch: null as DropDownItem,
        organizationSearch: null as DropDownItem,
    };
    view: "code" | "form" = "form";
    readOnly: boolean = false;
    scopeOptions: Doc.ScopeOptions;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        if (this.mode === "view") {
            this.readOnly = true;
            this.scopeOptions = Doc.scopeOptions;
        }
        this.setLoading(true);
        const defaultFileNamesPromise = this.getDefaultFileNames();
        if (this.mode === "create") {
            this.document = this.newDocument();
            await defaultFileNamesPromise; // Only need to await it if we are creating
            this.document.file_name = this.defaultFileNames[Doc.scope[this.document.document_type].reportId];
            this.scopeOptions = Doc.scopeOptions.filter((item) => this.defaultFileNames.hasOwnProperty(item.reportId));
        } else this.document = await this.getDocument(this.id);
        if (this.mode === "copy") {
            delete this.document.document_id; // No id => new document will be created
            this.document.document_name += " copy";
            this.scopeOptions = Doc.scopeOptions;
        }
        this.scope = Doc.scope[this.document.document_type];
        this.originalDocument = S25Util.deepCopy(this.document);
        this.parseBody();
        this.isInit = true;
        this.setLoading(false);
        this.changeDetector.detectChanges();
    }

    getDocument(id: number) {
        if (this.isTemplate) return DocumentService.getDocumentTemplate(id);
        return DocumentService.getDocument(id);
    }

    async getDefaultFileNames() {
        this.defaultFileNames = await ReportService.getDefaultDocumentFileNames();
    }

    newDocument(): Document {
        return {
            document_name: "New Document",
            document_type: "event",
        } as Document;
    }

    setLoading(loading: boolean) {
        if (loading) S25LoadingApi.init(this.elementRef.nativeElement);
        else S25LoadingApi.destroy(this.elementRef.nativeElement);
    }

    async showPreview(itemId?: number) {
        if (!this.eventReference) return alert("Please enter an event reference");
        this.setLoading(true);
        if (!itemId) {
            const [eventId, error] = await S25Util.Maybe(EventService.getEventIdByLocator(this.eventReference));
            if (error || !eventId) {
                this.setLoading(false);
                return alert("Failed to load event, please make sure your event reference is correct.");
            }
            this.eventId = eventId;
            this.changeDetector.detectChanges();
        }
        if (this.document.document_type === "reservation" && !itemId) return this.setLoading(false);
        if (this.document.document_type === "invoice" && !itemId) return this.setLoading(false);

        //todo: related events
        //todo: specific organization (billing)
        //todo: calculate total price from eventData (potentially with related events and potentially only for specific org id)
        //todo: override primary org with org id sent it (if any)
        const { header_text, body_text, footer_text } = this.document;
        const runId = await DocumentService.getEventDocumentRunIdFromText(
            header_text,
            body_text,
            footer_text,
            null,
            this.eventId,
        );

        const context = { num_parm1: this.eventId, documentRunId: String(runId) };
        if (this.document.document_type === "event") {
            const id = this.document.report_id || Reports.Reports.EventShell.id;
            return this.putReportRequest(id, "Event Contract", context);
        }
        if (this.document.document_type === "reservation") {
            const id = this.document.report_id || Reports.Reports.ReservationShell.id;
            return this.putReportRequest(id, "Reservation", { ...context, char_parm4: itemId });
        }
        if (this.document.document_type === "organization") {
            const id = this.document.report_id || Reports.Reports.OrganizationShell.id;
            return this.putReportRequest(id, "Event" + " Organization", context);
        }
        if (this.document.document_type === "invoice") {
            const id = this.document.report_id || Reports.Reports.PaymentShell.id;
            return this.putReportRequest(id, "Invoice", { ...context, num_parm2: itemId });
        }
    }

    async putReportRequest(id: number, name: string, context?: { [key: string]: any }, devMode: boolean = false) {
        const [response, error] = await S25Util.Maybe(
            ReportService.putReportRequest(
                {
                    rpt_id: id,
                    rpt_name: name,
                    rpt_engine: "WS",
                    rpt_use: Use.None,
                    object_type: ObjectType.NonSpecific,
                },
                context,
                null,
                null,
                null,
                null,
                this.document.file_name,
                devMode ? "&dev_mode=T" : "",
            ),
        );
        if (error) return this.onError(error);

        if (typeof response === "string") alert(response);
        this.setLoading(false);
    }

    async onSave() {
        if (!this.validate()) return;
        const { document_type, document_id } = this.document;
        this.setLoading(true);
        const [resp, error] = await S25Util.Maybe(
            DocumentService.setDocument(document_id, document_type, this.document),
        );
        if (error) {
            this.setLoading(false);
            return this.onError(error);
        }

        const documentId = resp.root.itemId;
        this.document.document_id = documentId;
        if (this.mode === "create" || this.mode === "copy") {
            TelemetryService.sendWithSub(
                "DocMgmt",
                this.isTemplate ? "DM_Documents" : "DM_Templates",
                this.mode === "create" ? "DM_Create" : "DM_Copy",
            );
            const [ok, err] = await S25Util.Maybe(this.createReport(documentId));
            if (err) return this.onError(err);
        } else if (this.mode === "edit" && this.document.report_id) {
            TelemetryService.sendWithSub("DocMgmt", this.isTemplate ? "DM_Documents" : "DM_Templates", "DM_Edit");
            const [doc, og] = [this.document, this.originalDocument];
            if (doc.document_type !== og.document_type) {
                // You can't easily change the scope of a report, so, if the scope of the document changed,
                // then we need to delete the old report and create a new one
                await this.deleteReport();
                const [ok, err] = await S25Util.Maybe(this.createReport(documentId));
                if (err) return this.onError(err);
            } else if (doc.document_name !== og.document_name || doc.file_name !== og.file_name) {
                // We only need to update the associated report if the document name or the report file name changed
                const [ok, err] = await S25Util.Maybe(this.updateReport());
                if (err) return this.onError(err);
            }
        }
        this.setLoading(false);
        this.saved.emit();
    }

    validate() {
        const doc = this.document;
        let message: string;
        if (!doc.document_name) message = "Please enter a name";
        else if (!doc.body_text) message = "Please enter a body";
        else if (this.scope.type === "tableBuilder" && this.view === "form") {
            // Check that columns requiring an ID have one
            let invalidColumn: TableBuilder.Column;
            outer: for (const table of this.tables) {
                for (const column of table) {
                    const requireData = column.variable.dataType && column.variable.dataType !== "format";
                    if (requireData && !column.data) {
                        invalidColumn = column;
                        break outer;
                    }
                }
            }
            if (invalidColumn) message = `Please select an item for column ${invalidColumn.header}`;
        }

        if (message) {
            alert(message);
            return false;
        }
        return true;
    }

    onCopy() {
        this.copied.emit();
    }

    onCancel() {
        this.cancelled.emit();
    }

    async onError(error: any) {
        this.setLoading(false);
        if (error?.error?.results?.error?.msg === "Report name already exists") {
            const { document_type, document_id } = this.document;
            S25Util.showError(
                "A report with the same name already exists. Please choose a new document name and try again.",
            );
            if (this.mode === "create" || this.mode === "copy") {
                // Delete doc and let user try again
                await DocumentService.deleteDocument(document_id);
            } else if (this.mode === "edit") {
                // Reset document
                await DocumentService.setDocument(document_id, document_type, this.originalDocument);
            }
            return;
        }
        S25Util.showError(error);
    }

    onScopeChange(scope: Doc.Scope) {
        // reset header, text and footer when scope  switch from an excel scope
        if (this.scope.type === "tableBuilder") {
            this.document.header_text = "";
            this.document.body_text = "";
            this.document.footer_text = "";
        }

        // If file name is still default, update it to the new default file name
        if (this.document.file_name === this.defaultFileNames[this.scope.reportId]) {
            this.document.file_name = this.defaultFileNames[scope.reportId];
        }
        // Update variables
        this.scope = scope;
        this.document.document_type = scope.id;

        // If new scope is table builder, set default columns
        if (scope.type === "tableBuilder") {
            this.view = "form";
            this.tables = scope.tableBuilder.map((table) => {
                const variables = BpeVarsConst.Items[table.key].filter((item) => !item.isGroup);
                return variables.map((item) => ({
                    header: item.txt,
                    variable: item,
                    width: item.width,
                }));
            });
            this.tableTab = scope.tableBuilder[0];
            this.updateQueryVariable();
            this.document.file_name = this.document.file_name.replace(/.pdf$/, ".xml"); // Switch extension from pdf to xml
        }
    }

    async createReport(documentId: number) {
        const report = await ReportService.getReportMeta(this.scope.reportId);
        const reportId = await ReportService.newReportId();
        report.status = "new";
        for (let param of report.parameter) param.status = "new";
        for (let run of report.report_run) run.status = "new";
        report.report_id = reportId;
        report.report_name = this.document.document_name;
        report.content_disposition = `attachment; filename=${this.document.file_name}`;
        report.document_id = documentId;
        report.report_engine = "DM";
        await ReportService.updateReport(report);
    }

    async updateReport() {
        // Update report with new document name and file name
        const report = await ReportService.getReportMeta(this.document.report_id);
        report.report_name = this.document.document_name;
        report.content_disposition = `attachment; filename=${this.document.file_name}`;
        await ReportService.updateReport(report);
    }

    deleteReport() {
        // Delete associated report
        if (!this.document.report_id) return;
        return ReportService.deleteReport(this.document.report_id);
    }

    async toggleView() {
        if (this.view === "code") {
            const ok = await this.confirmExitCodeMode.open();
            if (!ok) return;
            this.view = "form";
            this.parseBody();
            this.changeDetector.detectChanges();
        } else {
            this.view = "code";
        }
    }

    onColumnsChange() {
        this.updateQueryVariable();
    }

    updateQueryVariable() {
        const scope = this.scope;
        if (scope.type !== "tableBuilder") return;

        this.document.body_text = this.tables
            .map((table, i) => {
                const columns = BpeVarsUtil.getTableBuilderColumnsStr(table, true);
                if (!columns) return "";
                return `{{for: ${scope.tableBuilder[i].variable}: ${columns} :end-for}}`;
            })
            .join("\n\n");
    }

    async showQueryPreview() {
        const { start, end, eventSearch, locationSearch, resourceSearch, organizationSearch } = this.queryPreview;
        switch (this.scope.id) {
            case "eventListing":
            case "rsrvListing":
                if (!eventSearch) return alert("Please select an event search to preview");
                break;
            case "locListing":
                if (!locationSearch) return alert("Please select a location search to preview");
                break;
            case "resListing":
                if (!resourceSearch) return alert("Please select a resource search to preview");
                break;
            case "orgListing":
                if (!organizationSearch) return alert("Please select a organization search to preview");
                break;
        }

        this.setLoading(true);
        const [documentRun, err] = await S25Util.Maybe(
            DocumentService.setDocumentRun(this.document.document_id, "", this.document.body_text, ""),
        );
        if (err) return this.onError(err);

        const context = {
            date_parm1: S25Util.date.toS25ISODateTimeStr(S25Util.date.toStartOfDay(start.date)),
            date_parm2: S25Util.date.toS25ISODateTimeStr(S25Util.date.toEndOfDay(end.date)),
            ev_query_id: eventSearch?.itemId,
            rm_query_id: locationSearch?.itemId,
            rs_query_id: resourceSearch?.itemId,
            ac_query_id: organizationSearch?.itemId,
            documentRunId: String(documentRun.root.itemId),
        };

        return this.putReportRequest(this.document.report_id || this.scope.reportId, this.scope.label, context, true);
    }

    onPreviewDateChange() {
        if (this.queryPreview.start.date > this.queryPreview.end.date) {
            this.queryPreview.end.date = S25Util.date.clone(this.queryPreview.start.date);
        }
    }

    findVariableAndData(variables: VariableI[], varStr: string) {
        for (const variable of variables) {
            if ("dataType" in variable) {
                // Need to match to variable and data, for example
                // deposit.paymentStatus => $data.paymentStatus, data = deposit
                // totalTaxType.123 => totalTaxType.$data, data = 123
                const reVar = S25Util.escapeRegExp(variable.val).replace(/\\\$data/g, "(.*)");
                const regex = new RegExp(`^${reVar}$`);
                const match = varStr.trim().match(regex);
                if (!match) continue;

                const data = match[1]; // First matched $data
                return { variable, data };
            } else if (varStr.trim() === variable.val) {
                return { variable };
            }
        }
        return {};
    }

    parseBody() {
        // If the type is table builder then we need to parse the body text to get the columns
        const scope = this.scope;
        if (scope.type !== "tableBuilder") return;

        this.tableTab = scope.tableBuilder[0];
        this.tables = new Array(scope.tableBuilder.length).fill(null).map((_) => []);
        for (const { groups } of Array.from(this.document.body_text.matchAll(Doc.tableBuilderRegex))) {
            const i = scope.tableBuilder.findIndex((table) => table.variable === groups.variable);
            const table = scope.tableBuilder[i];
            if (!table) continue;

            const variables = BpeVarsConst.Items[table.key];
            const columns: TableBuilder.Column[] = [];
            const tableMatches = Array.from(groups.table.matchAll(Doc.tableBuilderColumnRegex));
            for (const { groups } of tableMatches) {
                const { variable, data } = this.findVariableAndData(variables, groups.variable);
                if (!variable) continue;

                const formatName = groups.format?.trim() || "";
                const format = formatName && BpeVarsConst.Items.dateFormats.find((format) => format.val === formatName);
                columns.push({
                    header: groups.header.trim(),
                    variable,
                    width: Number(groups.width) || 1, // Default to 1 if no width is provided
                    format,
                    data: data && { val: data },
                });
            }

            this.tables[i] = columns;
        }
    }

    onSampleClick() {
        if (!this.document.sampleFilename) return;
        DocumentService.downloadSample(this.document.document_id, this.document.sampleFilename);
    }
}
